import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';

export default function configureStore(preloadedState) {
  console.log('preloadedState is:', preloadedState);
  const store = createStore(
    rootReducer, 
    preloadedState, 
    applyMiddleware(
      thunkMiddleware,
      promise,
    )
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer);
    });
  }
  return store;
}