/* @desc 加载错误页面
 * @Author: guoguo 
 * @Date: 2020-02-25 20:32:32 
 * @Last Modified by: guoguo
 * @Last Modified time: 2020-02-28 10:33:58
 */
import React, { Component } from 'react';

const styles = {
  img403: {
    position: 'absolute',
    top: '200px',
    left: '0',
    display: 'block',
    width: '100%',
  },
  backBtn: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#de5247',
    borderRadius: '4px',
    height: '30px',
    lineHeight: '30px',
    width: '64px',
    color: '#fff',
    fontSize: '14px',
    textAlign: 'center',
  }
};

export default class Forbidden403 extends Component{
  constructor(props) {
    super(props);
    this.state = {
      btnTop: 0, // 返回按钮的top值
    };
  }

  componentDidMount() {
    const imgTop = parseInt(document.documentElement.clientWidth * 393 / 750, 10);
    this.setState({
      btnTop: imgTop + 210,
    });
  }

  /**
   * @description 刷新页面
   */
  refresh = () => {
    window.location.reload();
  }

  render() {
    const { btnTop } = this.state;
    return (
      <div>
        <img style={styles.img403} src={require('../../assets/img/error.jpg')} alt="" />
        <div style={Object.assign({}, styles.backBtn, { top: btnTop })} onClick={this.refresh}>刷新</div>
      </div>
    );
  }
}
