import {
  USER_INFO
} from '../types/Base';

const initialState = {
  userInfo: null, // 用户信息
};

export default function base(state = initialState, action) {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    default:
      return state;
  }
}
