import 'react-app-polyfill/ie9';
import configureStore from '../store/configureStore';
import React from 'react';
import ReactDOM from 'react-dom';
import routes from '../routes';
import { Provider } from 'react-redux';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import { renderRoutes } from "react-router-config";
import '../assets/css/base.less';
import 'antd-mobile/es/global';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    {
      process.env.NODE_ENV === 'development' ? (
        <HashRouter>
          <div>{ renderRoutes(routes()) }</div>
        </HashRouter>
      ) : (
        <BrowserRouter>
          <div>{ renderRoutes(routes()) }</div>
        </BrowserRouter>
      )
    }
  </Provider>,
  document.getElementById('root')
);
