import React from 'react';
import './building.less';

const building = require('../../assets/img/building.svg');

export default function Building() {
  return (
    <div className="building-box">
      <img src={building} alt="" />
      <div className="tips">正在建设中..</div>
    </div>
  );
};
