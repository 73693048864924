/*
 * @Author: yanghong 
 * @Date: 2020-02-25 20:27:46 
 * @Last Modified by: songyang
 * @Last Modified time: 2023-01-11 11:10:26
 */
import React from 'react';
import { TextArea, Button, Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { createForm } from 'rc-form';
import * as actions from '../../actions/Notice';
import * as BaseActions from '../../actions/Base';
import zx from '../../assets/js/zhixinHybrid.min.js';
import './Index.less';

@connect(
  state => ({
    userInfo: state.Base.userInfo,
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch),
    baseActions: bindActionCreators(BaseActions, dispatch),
  })
)
class Announcement extends React.Component {
  mdParser = null
  constructor(props) {
    super(props);
    this.state = {
      editFlag: false, // 判断登录者能否编辑群公告
      announceValue: '',
      teamId: '',
      doRender: false, // 决定何时渲染文本域 防止闪烁
      textHeight: document.documentElement.clientHeight - 100,
      timeFlag: true,
      keyboardH: 0,// 键盘高度
      top: 0,
      andriodFlag: false,
      scrollY: 0
    };
  }
  componentWillMount() {
    document.title = '群公告';
    const { teamId } = this.props.match.params;
    const { userInfo } = this.props;
    // 查询当前群组信息
    this.props.actions.getTeamDetails(teamId)
      .then(res => {
        const teamDetails = res.data;
        this.setState({
          announceValue: teamDetails.content || '',
        });
        // 判断当前用户在群组中的身份，来决定用户是否有编辑（发布）公告的权限
        if (teamDetails && teamDetails.teamMembers) {
          // 遍历members来确认当前用户的权限信息
          const adminMembers = teamDetails.teamMembers.filter(item => item.type === 0 || item.type === 1);
          if (adminMembers.map(item => item.userId).indexOf(userInfo.id) > -1) {
            // 当前用户有编辑权限
            this.setState({
              editFlag: true,
            });
          }
        }
        this.setState({
          doRender: true,
        });
      })
      .catch(err => {
        console.log(err);
        Toast.show({
          icon: 'fail',
          content: '获取群公告失败',
          duration: 0,
        });
        this.setState({
          doRender: true,
        });
      });
    this.setState({
      teamId
    });
  }
  componentDidMount() {
    const isIos = !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    const winHeight = document.documentElement.clientHeight;
    console.log('window resize, document clentH is:', document.documentElement.clientHeight);
    window.onresize = () => {
      console.log('window resize, document clentH is:', document.documentElement.clientHeight);
      if (!isIos) {
        // 重新设置一下body的高度
        document.getElementsByTagName("body")[0].style['min-height'] = document.documentElement.clientHeight;
        document.getElementsByTagName("body")[0].style['height'] = document.documentElement.clientHeight;
        this.setState({
          textHeight: document.documentElement.clientHeight - document.getElementById('notice-foot').clientHeight - 10,
        });
        const thisHeight = document.documentElement.clientHeight;
        if (Math.abs(winHeight - thisHeight) > 50) {
          this.setState({ andriodFlag: true });
        } else {
          this.setState({ andriodFlag: false });
        }
      }
    };
    const iosVersion = isIos ? window.navigator.userAgent.toLowerCase().match(/cpu iphone os (.*?) like mac os/) &&
      window.navigator.userAgent.toLowerCase().match(/cpu iphone os (.*?) like mac os/)[1].replace(/_/g, ".") : null;

    zx.ready(() => {
      if (isIos && iosVersion.replace(/\.\S+/, '') > 11) { // ios版本 > 11监听键盘高度，并动态改变底部评论框的bottom值
        zx.keyboardToggle = (result) => {
          console.log('keyboardToggle result is', result);
          this.setState({
            keyboardH: result.keyboardH || 0,
            top: document.documentElement.clientHeight - result.keyboardH
          });
          // window.CourseCommentThis.keyboardToggle(result)
        };
      }
    });
    window.onload = () => {
      this.setState({
        textHeight: document.documentElement.clientHeight - document.getElementById('notice-foot').clientHeight - 20,
      });
    };
  }
  // 键盘高度变化
  // keyboardToggle = (result) => {
  //   console.log(result)
  //   this.setState({
  //     keyboardH: result.keyboardH || 0,
  //     top: document.documentElement.clientHeight - result.keyboardH
  //   })
  // }
  /**
   * @description 提交群公告
   */
  release = () => {
    let { announceValue, teamId } = this.state;
    announceValue = announceValue.trim();
    Toast.show({
      icon: 'loading',
      content: '提交中...',
    });
    // if (getStrBytesLength(announceValue) > 2500) {
    //   Toast.fail('内容长度超出限制！');
    //   return;
    // }
    this.setState({ timeFlag: false }, () => {
      this.props.actions.releaseNotice(teamId, announceValue)
        .then(res => {
          this.setState({ timeFlag: true });
          let title = '';
          if (announceValue.length > 20) {
            title = announceValue.substring(0, 20);
          } else {
            title = announceValue;
          }
          if (announceValue.trim().length) {
            let announceContent = '';
            if (announceValue.length > 500) {
              announceContent = announceValue.substring(0, 500).replace(/\n/g, '  \n') + '...';
            } else {
              announceContent = announceValue.replace(/\n/g, '  \n');
            }
            window.im.sendAnnouncementMessage({ title, groupId: teamId, content: announceContent, 'atList': ['@all'] });
          }
          window.route.finish();
          Toast.show({
            icon: 'success',
            content: '发布成功！',
          });
        })
        .catch((err) => {
          this.props.baseActions.logError(err, 'Notice');
          this.setState({ timeFlag: true });
          Toast.show({
            icon:'fail',
            content: '发布失败！',
          });
        });
        // .then(() => this.setState({ timeFlag: true }));
    });
  }
  /**
   * @description  群公告输入框change
   * @param {String} value 当前输入文本
   */
  changeAnnounce = value => {
    this.setState({
      announceValue: value
    });
  }

  

  /**
   * @description  返回
   */
  back = () => {
    window.route.finish();
  }
  render() {
    const { getFieldProps } = this.props.form;
    const { editFlag, doRender, timeFlag } = this.state;
    return (
      <div className="announce">
        {
          doRender ? (
            <div>
              <div className="content">
                <TextArea
                  autoSize
                  className="content1"
                  {...getFieldProps('note1')}
                  value={this.state.announceValue}
                  placeholder={editFlag ? '请输入...' : '暂未设置公告'}
                  style={{ height: this.state.textHeight }}
                  disabled={!editFlag}
                  onChange={this.changeAnnounce}
                />
              </div>
              {
                editFlag ? <div className="releases" id="notice-foot">
                  <Button  size='large' block onClick={this.release} loading={!timeFlag} disabled={!timeFlag}>发布</Button>
                </div> :
                  <div className="foot" id="notice-foot">
                    <div className="lineLeft"></div>
                    <div className="text">仅群主和管理员可编辑</div>
                    <div className="lineRight"></div>
                  </div>
              }</div>) : null
        }
      </div>
    );
  }
}

export default withRouter(createForm()(Announcement));
