/*
 * @Author: guoguo 
 * @Date: 2018-10-18 16:48:56 
 * @Last Modified by: songyang
 * @Last Modified time: 2023-10-13 10:42:42
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import * as action from '../actions/Base';
import { bindActionCreators } from 'redux';
import { Toast } from 'antd-mobile';
import ForbiddenErr from '../component/Forbidden/Err';
import '../assets/css/base.less';

@connect(
  state => ({
  }),
  dispatch => ({
    actions: bindActionCreators(action, dispatch),
  })
)
class App extends Component {
  constructor(props) {
    super(props);
    this.clientId = '';
    this.state = {
      renderErrPage: false,
      renderFlag: false,
    };
  }

  componentDidMount() {
    // const appToken = window.api.getAppToken();
    // console.log('appToken is: ', appToken);

    window.onload = () => {
      // 注入bridge脚本
      const script = document.createElement("script");
      script.src = "/bridgeWeb.js";
      document.body.appendChild(script);
      // const authCode = ret.authCode;
    };
    Toast.show({
      icon: 'loading',
      content: '加载中…',
      duration: 0,
    });
    // 尝试获取用户信息
    this.props.actions.getUserInfo()
      .then(() => {
        // 获取用户信息成功，隐藏loading，并开始渲染页面
        Toast.clear();
        this.setState({
          renderFlag: true,
        });
        this.props.actions.syncGroup();
      })
      .catch((err) => {
        this.clientId = err.response && err.response.data && err.response.data.data;
        console.log('verCode Time is:', Date.parse(new Date()));
        // 获取用户信息失败，判定用户没有登录，添加onBridgeReady监听，供bridge调用
        document.addEventListener('onBridgeReady', this.bridgeReady);
      });
  }
  componentWillUnmount() {
    // 组件卸载时，移除onBridgeReady事件监听
    document.removeEventListener('onBridgeReady', this.bridgeReady);
  }
  componentDidCatch(error) {
    this.props.actions.logError(error, 'componentDidCatch');
    this.setState({ renderErrPage: true });
  }

  /**
   * @description js能力准备完毕
   */
  bridgeReady = () => {
    console.log('this.clientId is:', this.clientId);
    window.api.requestAuthCode({
      // h5AppId: '560816237a8f49dd99e5ac0393b35ced',
      h5AppId: this.clientId,
    }, (ret) => {
      console.log('res:', ret);
      const authCode = ret.authCode;
      this.props.actions.LoginForCode(authCode)
        .then(() => {
          console.log('LoginForCode is:', Date.parse(new Date()));
          // 获取用户信息成功，隐藏loading，并开始渲染页面
          Toast.clear();
          this.setState({
            renderFlag: true,
          });
        })
        .catch(err => {
          this.props.actions.logError(err, 'bridgeReady');
          if (err.response && err.response.status === 403) {
            Toast.show({
              icon: 'fail',
              content: '权限不足！',
              duration: 2000,
            });
            // 2s后重定向到403页面
            setTimeout(() => {
              this.props.history.replace('/403');
            }, 2000);
          } else {
            Toast.show({
              icon: 'fail',
              content: '加载错误！',
              duration: 2000,
            });
            // 渲染错误页面
            this.setState({
              renderFlag: true,
              renderErrPage: true,
            });
          }
        });
    });
  }

  render() {
    const { renderFlag, renderErrPage } = this.state;
    return renderFlag ? (
      renderErrPage ? (
        <ForbiddenErr />
      ) : (
        renderRoutes(this.props.route.routes)
      )
    ) : null;
  }
}

App.propTypes = {
  route: PropTypes.object,
  actions: PropTypes.object,
};

export default App;