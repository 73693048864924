/* @desc 群公告相关actions
 * @Author: yanghong 
 * @Date: 2020-02-25 21:02:59 
 * @Last Modified by: songyang
 * @Last Modified time: 2023-01-11 10:28:38
 */
import axios from 'axios';

/**
 * @description 获取群组详情
 * @param {String} teamId 群组id
 */
export function getTeamDetails(teamId) {
  return () => {
    return axios.get('/api/team/details', {
      params: { teamId }
    })
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(err => Promise.reject(err));
  };
}
/**
 * @description 修改群公告
 * @param {String} id 公告id
 * @param {String} content 修改的公告内容
 */
export function releaseNotice(id, content) {
  return () => {
    return axios.put('/api/team/notice', {
      id, content
    })
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(err => Promise.reject(err));
  };
}