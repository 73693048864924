import App from '../container/App';
import Notice from '../component/Notice/Index';
import WorkBench from '../component/WorkBench/Index';
import Forbidden403 from '../component/Forbidden/403';
import Building from '../component/WorkBench/Building';

export default function routes(store) {
  return [
    {
      path: '/403',
      component: Forbidden403,
    },
    {
      component: App,
      routes:[
        {
          path: '/notice/:teamId',
          exact: true,
          component: Notice,
        },
        {
          path: '/workbench',
          exact: true,
          component: WorkBench,
        },
        {
          path: '/workbench/building',
          component: Building,
        },
      ]
    }
  ];
}
