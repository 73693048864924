import {
  PROCESS_LIST, TASK_LIST, INITIATE_LIST
} from '../types/WorkFlow';

const initialState = {
  processList: [], // 流程列表
  taskList:[], // 任务列表
  initiateList:[], // 我的发起列表
};
export default function workflow(state = initialState, action) {
  switch (action.type) {
    case PROCESS_LIST:
      return {
        ...state,
        processList: action.processList,
      };
    case TASK_LIST:
      return {
        ...state,
        taskList: action.taskList,
      };
    case INITIATE_LIST:
      return {
        ...state,
        processList: action.initiateList,
      };
    default:
      return state;
  }
}