/**
 * types 
 * @authors yanjixiong 
 * @date    2016-07-12 16:59:21
 */

module.exports = {
  TREE_FORWARD: 'TREE_FORWARD',
  TREE_BACK: 'TREE_BACK',
  TREE_BACK_ANY: 'TREE_BACK_ANY',

  SEARCH: 'SEARCH',
  KEYWORD: 'KEYWORD',

  INIT: 'INIT',

  GET_CHILDREN: 'GET_CHILDREN',
  GET_SUB_AND_PERSON: 'GET_SUB_AND_PERSON',

  ADD_FRIEND: 'ADD_FRIEND',
  ADD_DEPT_FRIEND: 'ADD_DEPT_FRIEND',

  TOKEN: 'TOKEN',

  LOADED: 'LOADED',
  LOADING: 'LOADING',

  SELECT_TREE_FORWARD: 'TREE_FORWARD',
  SELECT_TREE_BACK: 'TREE_BACK',

  SELECT_SEARCH: 'SEARCH',
  SELECT_KEYWORD: 'KEYWORD',

  SELECT_INIT: 'INIT',

  SELECT_GET_CHILDREN: 'GET_CHILDREN',
  SELECT_GET_SUB_AND_PERSON: 'GET_SUB_AND_PERSON',

  SELECT_PEOPLE: 'SELECT_PEOPLE',
  UNSELECT_PEOPLE: 'UNSELECT_PEOPLE',
  SELECT_PEOPLE_ALL: 'SELECT_PEOPLE_ALL',
  UNSELECT_PEOPLE_ALL: 'UNSELECT_PEOPLE_ALL',

  SELECT_TOKEN: 'TOKEN',

  SELECT_LOADED: 'LOADED',
  SELECT_LOADING: 'LOADING',

  BASE_INFO: 'BASE_INFO',

  STORE_FRIEND: 'STORE_FRIEND',
  CHANGE_FRIEND: 'CHANGE_FRIEND',
};
