import { combineReducers } from 'redux';
import Base from './Base';
import WorkFlow from './WorkFlow';
import Org from './Org';
import OrgSelect from './OrgSelect';

const rootReducer = combineReducers({
  Base,
  WorkFlow,
  Org,
  OrgSelect,
});

export default rootReducer;