/*
 * @Author: yanghong 
 * @Date: 2020-02-25 20:27:46 
 * @Last Modified by: songyang
 * @Last Modified time: 2023-05-27 10:28:16
 */
import React from 'react';
import { Swiper, Toast } from 'antd-mobile';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { uniqeByKeys } from '../../utils/unique';
import * as BaseActions from '../../actions/Base';
import './Index.less';

const building = require('../../assets/img/building.svg');
const clouddiskImage = require('../../assets/img/clouddisk.png');

let longPressTimer;

@connect(
  state => ({
    userInfo: state.Base.userInfo,
  }),
  dispatch => ({
    baseActions: bindActionCreators(BaseActions, dispatch),
  })
)
class WorkBench extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      workCate: [],
      popular: [], // 常用应用
      loadError: false,
      hasNoConfig: false,
      showDeletePopular: false, // 是否显示删除常用应用按钮
    };
  }

  componentDidMount() {
    this.initData();
    document.addEventListener('click', this.hideDeletePopular);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDeletePopular);
  }

  // 隐藏删除常用按钮
  hideDeletePopular = () => {
    this.setState({
      showDeletePopular: false,
    });
  };

  initData = async () => {
    const { userInfo: { config } } = this.props;
    // get banners
    await this.getBanners(config.productId)
      .then(res => {
        this.setState({ banners: res.data.data || [] });
        return null;
      })
      .catch(err => {
        console.log('获取banner列表失败', err);
      });
    // get apps
    await this.getApps(config.productId)
      .then(res => {
        const newWorkCate = res.data.data || [];
        if (newWorkCate.length) {
          // 如果当前配置了应用列表，则需要尝试初始化常用应用
          this.initPopulars(newWorkCate);
          if (newWorkCate[0].items && newWorkCate[0].items.length) {
            newWorkCate[0].items.push({
              id: 'clouddisk',
              url: 'clouddisk',
              name: '云盘',
              iconUrl: clouddiskImage
            });
          }
        }
        this.setState({
          workCate: newWorkCate,
          hasNoConfig: !newWorkCate.length, // 标识当前暂未配置应用
        });
        return null;
      })
      .catch(err => {
        console.log('获取应用列表失败', err);
        Toast.show({
          icon: 'fail',
          content: '获取应用列表失败',
        });
        this.setState({
          loadError: true,
        });
      });
  };

  /**
   * @description 从localstorage中初始化常用应用
   * @param {object[]} apps 应用配置列表
   */
  initPopulars = (apps) => {
    const popular = window.localStorage.getItem('workBenchPopular');
    if (popular) {
      try {
        let parseData = JSON.parse(popular);
        // 遍历取出所有的appId
        let allAppIds = [];
        for (let i = 0; i < apps.length; i++) {
          const { items } = apps[i];
          allAppIds = [...allAppIds, ...(items || []).map(v => v.id)];
        }
        // 过滤掉不在应用配置列表中的常用应用
        parseData = parseData.filter(item => allAppIds.includes(item.id));
        console.log('parseData is', parseData);
        this.setState({
          popular: parseData,
        });
        // 重新保存到localstorage
        window.localStorage.setItem('workBenchPopular', JSON.stringify(parseData));
      } catch (error) {
        console.log('初始化常用应用失败', error);
      }
    }
  };

  /**
   * @description 获取banner列表
   * @param {string} productId app产品id
   */
  getBanners = (productId) => {
    return axios.get(`/platform/platform/v1/work/banner/${productId}`);
  };

  /**
   * @description 获取app列表
   * @param {string} productId app产品id
   */
  getApps = (productId) => {
    return axios.get(`/platform/platform/v1/product/h5appTree/${productId}`);
  };

  /**
   * @description banner/app item click
   * @param {object} item data item
   * @param {number} type 1:banner data item, 2:app data item
   */
  itemClick = (item, type) => {
    if (type === 2 && item.id !== 'clouddisk') {
      this.addPopularApp(item);
    }
    const title = item.name;
    try {
      if (item.id === 'clouddisk') {
        window.route.navigateToCloudDisk({ title: '云盘' });
      } else {
        window.route.navigateToWeb({
          url: `${item.url}`,
          title,
          toolbar: {
            needToolbar: true,
            needMoreMenu: false,
          },
        });
      }
    } catch (error) {
      console.log('error is: ', error);
      // this.props.history.push('/workbench/building');
    }
  };

  /**
   * @description 添加常用应用，最多8个
   * @param {object} item app data item
   */  
  addPopularApp = (item) => {
    const newPopular = uniqeByKeys([item, ...this.state.popular], ['id']);
    if (newPopular.length > 8) {
      newPopular.splice(newPopular.length - 1, 1);
    }
    this.setState({
      popular: newPopular,
    });
    // update to localstorage
    window.localStorage.setItem('workBenchPopular', JSON.stringify(newPopular));
  };

  /**
   * @description 删除常用应用
   * @param {object} item app data item
   */  
  delPopularApp = (e, item) => {
    this.stopPropagation(e);
    const { popular } = this.state;
    const indexOf = popular.map(v => v.id).indexOf(item.id);
    if (indexOf > -1) {
      const copyData = [...popular];
      copyData.splice(indexOf, 1);
      this.setState({
        popular: copyData,
      });
      window.localStorage.setItem('workBenchPopular', JSON.stringify(copyData));
    }
  };

  // 常用应用item TouchStart
  popularTouchStart = () => {
    longPressTimer = setTimeout(() => {
      longPressTimer = 0;
      console.log('长按');
      // 当前为长按，显示处删除常用应用icon
      this.setState({
        showDeletePopular: true,
      });
    }, 400);
  };

  /**
   * @description 常用应用item TouchEnd
   * @param {object} appItem app item
   */
  popularTouchEnd = (appItem) => {
    clearTimeout(longPressTimer);
    if (longPressTimer !== 0) {
      console.log('点击');
      this.itemClick(appItem, 2);
    }
  };

  // 常用应用item TouchMove
  popularTouchMove = () => {
    clearTimeout(longPressTimer);
    longPressTimer = 0;
  };

  stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const { banners, workCate, hasNoConfig, loadError, popular, showDeletePopular } = this.state;
    return (
      <div className="workbench">
        <div className="wing-blank">
          {banners.length ? <div className="line-gap" /> : null}
          {banners.length ? (
            <Swiper autoplay loop>
              {banners.map(val => (
                <Swiper.Item key={val.id}>
                  <div className="swiper-img-box" onClick={() => this.itemClick(val, 1)}>
                    <img src={val.imgUrl} alt="" onLoad={() => { window.dispatchEvent(new Event('resize')); }} />
                  </div>
                </Swiper.Item>
              ))}
            </Swiper>
          ) : null}
        </div>
        {loadError ? (
          <div className="no-data">
            <img src={building} alt="" />
            <div className="tips">获取数据失败</div>
          </div>
        ) : (hasNoConfig ? (
          <div className="no-data">
            <img src={building} alt="" />
            <div className="tips">暂未配置工作台应用</div>
          </div>
        ) : (
          <div className="wing-blank" style={{ paddingTop: 9 }}>
            {popular.length ? (
              <div className="cate-box-wrap">
                <div className="cate-box">
                  <div className="cate-name">常用</div>
                  <div className="app-box">
                    {popular.map((appItem) => (
                      <div
                        className="app-item"
                        key={appItem.id}
                        onTouchStart={() => this.popularTouchStart(appItem)}
                        onTouchEnd={() => this.popularTouchEnd(appItem)}
                        onTouchMove={this.popularTouchMove}
                        onClick={() => console.log('click')}
                      >
                        <img src={appItem.iconUrl} alt="" />
                        <div>{appItem.name}</div>
                        {showDeletePopular ? (
                          <div
                            className="del-icon"
                            onTouchStart={this.stopPropagation}
                            onTouchEnd={this.stopPropagation}
                            onTouchMove={this.stopPropagation}
                            onClick={(e) => this.delPopularApp(e, appItem)}
                          >
                            <span className="del" />
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="line-gap" />
              </div>
            ) : null}
            {workCate.map((item) => (
              item.items ? (
                <div key={item.id} className="cate-box-wrap">
                  <div className="cate-box">
                    <div className="cate-name">{item.name}</div>
                    <div className="app-box">
                      {item.items.map((appItem) => (
                        <div className="app-item" key={appItem.id} onClick={() => this.itemClick(appItem, 2)}>
                          <img src={appItem.iconUrl} alt="" />
                          <div>{appItem.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="line-gap" />
                </div>
              ) : null
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(WorkBench);
