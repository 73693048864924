import {
  USER_INFO
} from '../types/Base';

import axios from 'axios';
/**
 * @description 获取当前登录用户的用户信息
 */
export function getUserInfo() {
  return dispatch => {
    return axios.get('/api/user/info')
      .then(res => {
        dispatch({
          type: USER_INFO,
          userInfo: res.data.data,
        });
        return Promise.resolve(res.data.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}

/**
 * @description 根据验证码登录，并获取用户信息
 * @param {String} verCode 验证码
 */
export function LoginForCode(verCode) {
  return (dispatch) => {
    return axios.post('/api/user/code', {
      verCode,
    })
      .then(res => {
        dispatch({
          type: USER_INFO,
          userInfo: res.data.data,
        });
        return Promise.resolve(res.data.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}

/**
 * @description 根据用户信息更新当前用户的所属组
 */
export function syncGroup() {
  return () => {
    return axios.post('/api/user/group')
      .then(res => {
        return Promise.resolve();
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}

/**
 * @description 打印异常日志
 * @params {Error} error
 */
export function logError(error, from) {
  return () => {
    return axios.post('/api/user/log', { error: error && error.stack ? error.stack : error, from })
      .then(res => {
        return Promise.resolve();
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}
